<template>
  <div class="reset_password_wrapper">
    <app-navbar />
    <app-wrapper>
      <div class="reset_password_form">
        <ui-card
          v-loading="loading"
          :heading="$t('auth.reset_password')"
          class="w-full max-w-lg"
        >
          <ui-form
            :submit-text="$t('auth.reset_password')"
            @submit="handleSubmit"
            @cancel="handleCancel"
          >
            <ui-form-field :label="$t('fields.email')" rules="required">
              <el-input type="email" v-model="email"></el-input>
            </ui-form-field>
          </ui-form>
        </ui-card>
      </div>
    </app-wrapper>
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import { resetPassword } from '@/app/auth/api';
import AppNavbar from '@/components/app/AppNavbarGuest';
import AppWrapper from '@/components/app/AppWrapperGuest';

export default {
  components: {
    AppNavbar,
    UiCard,
    UiForm,
    UiFormField,
    AppWrapper
  },

  data() {
    return {
      email: '',
      loading: false
    };
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const payload = { email: this.email };
        // eslint-disable-next-line no-unused-vars
        const result = await resetPassword(payload);
        this.$message({
          message: `An email has been sent to ${this.email}`,
          type: 'success'
        });
        this.$router.push({ name: 'login' });
      } catch (error) {
        this.$message({
          message: 'An error has occured. Try again',
          type: 'error'
        });
      }
      this.loading = false;
    },

    handleCancel() {
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style>
.reset_password_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.reset_password_form {
  margin: 5em auto 0 auto;
  width: 30em;
}
</style>
