<template>
  <div class="navbar_wrapper">
    <div class="teknisk_industrivern_navbar">
      <img
          :src="require('@/assets/images/TIV_nettkurs_logo.png')"
          alt="TIV_nettkurs_logo"
          class="teknisk_industrivern_navbar_logo"
      />
    </div>
    <div class="teknisk_industrivern_sub_navbar">
      <p class="teknisk_industrivern_sub_navbar_text">
        - Brannteknisk kompetansesenter
      </p>
      <div class="teknisk_industrivern_sub_navbar_option">
        <app-header-locale></app-header-locale>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeaderLocale from '@/components/app/AppHeaderLocale';

export default {
  name: 'AppNavbar',
  components: {
    AppHeaderLocale
  }
};
</script>

<style scoped>

.navbar_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.teknisk_industrivern_navbar {
  width: 100%;
  height: 7em;
  background-image: linear-gradient(to bottom, #E00010, #B60000);
  display: flex;
  justify-content: center;
}

.teknisk_industrivern_navbar_logo {
  display: flex;
  height: 100%;
}

.teknisk_industrivern_sub_navbar {
  display: flex;
  flex-direction: row;
  height: 3em;
  width: 100%;
  /*justify-content: space-between;*/
  justify-content: center;
  background-color: white;
}

.teknisk_industrivern_sub_navbar_text {
  display: flex;
  align-self: center;
}

@media only screen and (max-width: 1023px) {
  .teknisk_industrivern_sub_navbar_text {
    display: none;
  }
}

.teknisk_industrivern_sub_navbar_option {
  border-right: 0.1em rgba(210, 210, 210, 0.5) solid;
  border-left: 0.1em rgba(210, 210, 210, 0.5) solid;
  width: 11em;
  right: 8em;
  position: absolute;
  height: 3em;
}

</style>
