<template>
  <div class="app_top_image">
    <div class="app_page">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppWrapper'
};
</script>

<style scoped>
.app_top_image {
  display: flex;
  height: 30em;
  width: 100%;
  background-image: url('~../../../../images/backgrounds/default/background.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
}
.app_page {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  background: linear-gradient(to bottom, transparent 50%, #f2f2f2 0);
}
</style>
